import React from 'react'
// import Lottie from "react-lottie";

// components
import Container from '../components/container'
import RoadMap from '../components/roadmap'
import Faq from '../components/faq'
// import Swap from "../components/swap";
import Header from '../components/header'
import Footer from '../components/footer'
// import VideoPlayer from "../components/video-player";

// images
// import TwoSectionImage from "../assets/images/image_1.webp";
import ThirdSectionImage from '../assets/images/image_2.webp'
import Liquidity from '../assets/images/liquidity.png'
import Growth from '../assets/images/growth.png'
import Dev from '../assets/images/dev.png'
import Reflections from '../assets/images/reflections.png'
import EtherscanImage from '../assets/images/etherscan.png'
import CoinmarketcapImage from '../assets/images/coinmarketcap.png'
import CoingeckoImage from '../assets/images/coingecko.png'
import UniswapImage from '../assets/images/uniswap.png'
import tgbut from '../assets/images/tgbut.png'
import xbut from '../assets/images/xbut.png'
import dsbut from '../assets/images/dsbut.png'
import dtbut from '../assets/images/dtbut.png'
import Logo from '../assets/images/marmytitle.png'
import MarmySiteLogo from '../assets/images/marmysitelogo.png'
import hulhc from '../assets/images/hulhc.gif'
import marm1 from '../assets/images/marm1.gif'
import marm2 from '../assets/images/marm2.gif'
import marm3 from '../assets/images/marm3.gif'
import marm4 from '../assets/images/marm4.gif'
import marm5 from '../assets/images/marm5.gif'
import marm6 from '../assets/images/marm6.gif'
import marm7 from '../assets/images/marm7.gif'
import mp1 from '../assets/images/mp1.png'
import mp2 from '../assets/images/mp2.png'
import mp3 from '../assets/images/mp3.png'
import mp4 from '../assets/images/mp4.png'
import mp5 from '../assets/images/mp5.png'
import mp6 from '../assets/images/mp6.png'
import mp7 from '../assets/images/mp7.png'

// animations
// import * as animationData from "../assets/animations/top.json";

const Home = () => {
  // const animationOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice"
  //   }
  // };

  return (
    <div className="page-home">
      <Header />
      {/* section-1 start */}
      <div>
        <br />
        <br />
      </div>
      <div className="page-home__section bg-1 bg-mountain" id="section-1">
        <Container className="page-home__section-1">
          {/* <div className="page-home__section-1__lottie">
            <Lottie options={animationOptions} />
          </div> */}
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <img
            src={MarmySiteLogo}
            className="page-home__section-1__lottie"
            alt="MainImg"
            style={{
              width: 300,
              height: 300,
            }}
          />
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="page-home__section-1__title">
            <img src={Logo} alt="MainImg" style={{ width: 250, height: 70 }} />
          </div>
          <div className="page-home__section-1__btns">
            <a
              className="page-home__section-1__btns__btn-left"
              href="https://pump.fun/F9k4ucPb8L22awjjrTLGeDYb7NyXcfLYVHD63aeLpump"
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy Now
            </a>
            <a
              className="page-home__section-1__btns__btn-right"
              href="https://pump.fun/F9k4ucPb8L22awjjrTLGeDYb7NyXcfLYVHD63aeLpump"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chart
            </a>
          </div>

          <div className="page-home__section-1__partners">
            <img src={EtherscanImage} alt="etherscan" />
            <img src={UniswapImage} alt="uniswap" />
          </div>
        </Container>
        <div id="blossom_container"></div>
      </div>
      {/* section-1 end */}

      

      {/* section-5 start */}
      {/*
      <div className="page-home__section bg-1" id="section-5">
        <Container className="page-home__section-5">
          <div className="page-home__section-5__title">DISTRIBUTION</div>
          <br />
          <br />
          <div className="page-home__section-5__tax">
            <div className="page-home__section-5__tax__buy">
              <div className="page-home__section-5__tax__buy__group">
                <div className="page-home__section-5__tax__buy__group__item">
                  <img src={mp4} alt="liquidity" />
                  <div className="page-home__section-5__tax__buy__group__item__title">
                    25% Buy Now
                  </div>
                  <br />
                  <div className="page-home__section-5__tax__buy__group__item__paragraph">
                    25% of the $SCAI Supply will be sold at presale.
                  </div>
                </div>

                <div className="page-home__section-5__tax__buy__group__item">
                  <img src={mp5} alt="liquidity" />
                  <div className="page-home__section-5__tax__buy__group__item__title">
                    25% Liquidity Pools
                  </div>
                  <br />
                  <div className="page-home__section-5__tax__buy__group__item__paragraph">
                    25% of the $SCAI supply will be added to liquidity pools upon launch.
                  </div>
                </div>
              </div>
            </div>
            <div className="page-home__section-5__tax__sell">
              <div className="page-home__section-5__tax__sell__group">
                <div className="page-home__section-5__tax__sell__group__item">
                  <img src={mp6} alt="liquidity" />
                  <div className="page-home__section-5__tax__sell__group__item__title">
                    25% Exchanges
                  </div>
                  <br />
                  <div className="page-home__section-5__tax__sell__group__item__paragraph">
                    25% of the $SCAI supply is reserved for market-making on centralized exchanges
                  </div>
                </div>

                <div className="page-home__section-5__tax__sell__group__item">
                  <img src={mp7} alt="liquidity" />
                  <div className="page-home__section-5__tax__sell__group__item__title">
                    25% Marketing & Dev
                  </div>
                  <br />
                  <div className="page-home__section-5__tax__sell__group__item__paragraph">
                    25% of the supply is reserved for marketing and ongoing project costs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      */}
      {/* section-5 end */}

      {/* section-8 start */}
      {/* <div className="page-home__section bg-2">
        <Container className="page-home__section-8">
          <VideoPlayer
            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            poster={FirstSectionImage}
          />
        </Container>
      </div> */}
      {/* section-8 end */}

      {/* section-9 start */}
      {/*
      <div className="page-home__section bg-1" id="section-9">
        <Container className="page-home__section-9">
          <div className="page-home__section-9__title">FAQ</div>
          <br />
          <br />

          <div className="page-home__section-9__list">
            <Faq
              question="What Is $SCAI?"
              answer="$SCAI is the ultimate tool-base for the new AI paradigm that provides essential tools and learning resources for anyone interested in AI."
            />
            <Faq
              question="What is the $SCAI Mission?"
              answer="Our mission is to democratize AI by providing tools that allow anyone to build, learn and earn from AI, making it accessible to all regardless of their background or experience."
            />
            <Faq
              question="What is $BASED?"
              answer="$BASED is the Binance Smart Chain (BSC) token at the heart of $SCAI. It serves as the native token for the $SCAI platform, enabling users to access and use many of the platform's features and functionality and is utilized for payments such as trading fees and commissions on trades executed by the AI bots."
            />
            <Faq
              question="Where Can I Buy $BASED?"
              answer="$BASED currently runs only on the Binance Smart Chain, which means it can be best purchased and
sold through PancakeSwap."
            />
            <Faq
              question="How Does $SCAI make Money?"
              answer="$SCAI generates revenue through several channels. One of the primary sources of revenue is advertising. Another source of revenue for $SCAI is its marketplace. Finally, $SCAI generates revenue through its AI bots."
            />
          </div>
        </Container>
      </div>
      */}
      {/* section-9 end */}
      <Footer />
    </div>
  )
}

export default Home
