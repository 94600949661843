import React, { useState, useRef } from "react";

import { scrollTo } from "../utils";

// components
import Container from "./container";

// images
import Logo from "../assets/images/marmytitle.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const [isOpenCommunity, setIsOpenCommunity] = useState(false);

  const mobileAboutMenu = useRef<HTMLHeadingElement>(null);
  const mobileCommunityMenu = useRef<HTMLHeadingElement>(null);

  return (
    <div className="component-header">
      <Container className="component-header-container">
        <img className="component-header__logo" src={Logo} alt="logo" style={{
          width: 179,
          height: 50,
        }} />
        <div
          className={[
            "component-header__nav-mobile-btn",
            isOpen ? "active" : ""
          ].join(" ")}
          onClick={() => {
            setIsOpen(!isOpen);
            setIsOpenAbout(false);
            setIsOpenCommunity(false);
          }}
        >
          <span className="component-header__nav-mobile-btn__bar" />
          <span className="component-header__nav-mobile-btn__bar" />
          <span className="component-header__nav-mobile-btn__bar" />
        </div>
        <div
          className="component-header__nav-mobile"
          style={{ bottom: isOpen ? "0%" : "100%" }}
        >
          <div className="component-header__nav-mobile__link">
            <div
              className="component-header__nav-mobile__link__menu"
              style={{
                maxHeight: isOpenAbout
                  ? mobileAboutMenu.current
                    ? mobileAboutMenu.current.scrollHeight
                    : 0
                  : "0px"
              }}
              ref={mobileAboutMenu}
            >
              
              <div
                className="component-header__nav-mobile__link__menu__item"
                onClick={() => {
                  setIsOpen(false);
                  scrollTo({
                    id: "section-3"
                  });
                }}
              >
                COMMUNITY
              </div>
              <div
                className="component-header__nav-mobile__link__menu__item"
                onClick={() => {
                  setIsOpen(false);
                  scrollTo({
                    id: "section-4"
                  });
                }}
              >
                TOKENOMICS
              </div>
              <div
                className="component-header__nav-mobile__link__menu__item"
                onClick={() => {
                  setIsOpen(false);
                  scrollTo({
                    id: "section-5"
                  });
                }}
              >
                DISTRIBUTION
              </div>
              <div
                className="component-header__nav-mobile__link__menu__item"
                onClick={() => {
                  setIsOpen(false);
                  scrollTo({
                    id: "section-6"
                  });
                }}
              >
                HOW TO BUY
              </div>
            </div>
          </div>
          <div className="component-header__nav-mobile__link">
            <div
              className="component-header__nav-mobile__link__text"
              onClick={() => {
                setIsOpenCommunity(!isOpenCommunity);
              }}
            >
              <div className="component-header__nav-mobile__link__text__text">
                COMMUNITY
              </div>
              <div
                className="component-header__nav-mobile__link__text__dropdown"
                style={{
                  transform: isOpenCommunity ? "rotate(180deg)" : "rotate(0deg)"
                }}
              ></div>
            </div>
            <div
              className="component-header__nav-mobile__link__menu"
              style={{
                maxHeight: isOpenCommunity
                  ? mobileCommunityMenu.current
                    ? mobileCommunityMenu.current.scrollHeight
                    : 0
                  : "0px"
              }}
              ref={mobileCommunityMenu}
            >
              <a
                className="component-header__nav-mobile__link__menu__item"
                href="https://t.me/sadcatai"
                target="_blank"
                rel="noopener noreferrer"
              >
                TELEGRAM
              </a>
              <a
                className="component-header__nav-mobile__link__menu__item"
                href="https://x.com/sadcat_ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                TWITTER
              </a>
            </div>
          </div>
          <div className="component-header__nav-mobile__link">
          </div>
          <a
            className="component-header__nav-mobile__btn"
            href="https://pump.fun/F9k4ucPb8L22awjjrTLGeDYb7NyXcfLYVHD63aeLpump"
            target="_blank"
            rel="noopener noreferrer"
          >
            BUY NOW
          </a>
        </div>
        <div className="component-header__nav">
          <div className="component-header__nav__link">
            <div className="component-header__nav__link__menu">
              <div
                className="component-header__nav__link__menu__item"
                onClick={() => {
                  scrollTo({
                    id: "section-3"
                  });
                }}
              >
                COMMUNITY
              </div>
              <div
                className="component-header__nav__link__menu__item"
                onClick={() => {
                  scrollTo({
                    id: "section-4"
                  });
                }}
              >
                TOKENOMICS
              </div>
              <div
                className="component-header__nav__link__menu__item"
                onClick={() => {
                  scrollTo({
                    id: "section-5"
                  });
                }}
              >
                DISTRIBUTION
              </div>
              <div
                className="component-header__nav__link__menu__item"
                onClick={() => {
                  scrollTo({
                    id: "section-6"
                  });
                }}
              >
                HOW TO BUY
              </div>
            </div>
          </div>
          <div className="component-header__nav__link">
            <div className="component-header__nav__link__text">COMMUNITY</div>
            <div className="component-header__nav__link__dropdown"></div>
            <div className="component-header__nav__link__menu">
              <a
                className="component-header__nav__link__menu__item"
                href="https://t.me/sadcatai"
                target="_blank"
                rel="noopener noreferrer"
              >
                TELEGRAM
              </a>
              <a
                className="component-header__nav__link__menu__item"
                href="https://x.com/sadcat_ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                TWITTER
              </a>
            </div>
          </div>
          <div className="component-header__nav__divider"></div>
          <a
            className="component-header__nav__btn"
            href="https://pump.fun/F9k4ucPb8L22awjjrTLGeDYb7NyXcfLYVHD63aeLpump"
            target="_blank"
            rel="noopener noreferrer"
          >
            BUY NOW
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Header;
