import React from "react";

// import DiscordIcon from "../assets/images/discord.svg";
import TelegramIcon from "../assets/images/telegram.svg";
import TwitterIcon from "../assets/images/twitter.svg";
// import RedditIcon from '../assets/images/reddit.svg';
import GithubIcon from "../assets/images/github.svg";
import InstagramIcon from "../assets/images/instagram.svg";

const Footer = () => {
  return (
    <div className="component-footer">
      <div className="component-footer__section-1">
        <div className="component-footer__section-1__title">
          THE SADDEST CAT ON SOL
        </div>
        <br />
        <div className="component-footer__section-1__text">
          Come Cry with $SCAI <br /> <br />
        </div>
        <br />
        <br />
        <div className="component-footer__section-1__links">
          <a
            href="https://t.me/sadcatai"
            className="component-footer__section-1__links__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TelegramIcon} alt="social" width="20px" />
          </a>
          <a
            href="https://x.com/sadcat_ai"
            className="component-footer__section-1__links__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TwitterIcon} alt="social" width="20px" />
          </a>
        </div>
      </div>

      <div className="component-footer__divider"></div>

      <div className="component-footer__section-2">
        <div className="component-footer__section-2__text">
          The Content shared on this website is for information purpose only
          and, thus, should not be considered as financial advice.
          Trading/Investing is risky and you should never invest more than you
          can afford to lose. Cryptocurrencies are risky. Never invest more than
          you can afford to lose. You alone assume the sole responsibility of
          evaluating the merits and risks associated with the use of any
          information or other Content on the Site before making any decisions
          based on such information or other Content. $SCAI is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.
        </div>
      </div>
    </div>
  );
};

export default Footer;
